<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-20">
                <PassContent
                v-if="company"
                :company-id="company.id"
                :company-name="company.name"
                    :email-address="emailAddress"
                    :recipient-name="recipientName"
                    :is-actions-enabled="isActionsEnabled"
                    @update-status="handleUpdateStatus"
                />
                <div v-else>Loading...</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import PassContent from "../../components/Common/PassContent.vue"
import { useRoute } from 'vue-router'
import { reportCompanyService } from '@/api/reportCompanyService'

interface Company {
  id: string
  name: string
}

const route = useRoute()
const company = ref<Company | null>(null)
const emailAddress = ref('')
const recipientName = ref('')
const isActionsEnabled = ref(false)

const fetchCompanyData = async () => {
  try {

    let companyName = route.query.company_name || false;
    let companyWebsite = route.query.company_website || false;

    company.value = {
        id: 0,
        name: ''
    }

    if ( companyName && companyWebsite ) {
        
        let responseCompany = await reportCompanyService.getOrMakeCompany(companyName, companyWebsite);

        isActionsEnabled.value = responseCompany.will_use_status_feature

        company.value.id = responseCompany.id
        company.value.name = responseCompany.name
        recipientName.value = responseCompany.inbound_reply_to_name
        emailAddress.value = responseCompany.inbound_reply_to_email

    }

  } catch (error) {
    console.error('Failed to fetch company data:', error)
  }
}

const handleUpdateStatus = (status: string) => {
  // Implement status update logic
}

onMounted(() => {
  fetchCompanyData()
})
</script>