import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pt-20 d-flex flex-column transition overflow-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (!_ctx.isLoginPage && !_ctx.isRegisterPage && !_ctx.isForgotPassword && !_ctx.isLogout && !_ctx.isResetPassword && !_ctx.isPrivacyPage && !_ctx.isTermsPage)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (!_ctx.shouldHideHeader)
          ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.shouldHideHeader)
          ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 1 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "main-content d-flex flex-column transition overflow-hidden",
          style: _normalizeStyle({ 
           paddingTop: _ctx.shouldHideHeader ? '20px' : '', 
           paddingLeft: _ctx.shouldHideHeader && _ctx.shouldHideFooter ? '0' : _ctx.shouldHideHeader ? '25px' : '',
           paddingRight: _ctx.shouldHideHeader && _ctx.shouldHideFooter ? '0' : ''
         })
        }, [
          _createVNode(_component_router_view),
          (!_ctx.shouldHideFooter)
            ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
            : _createCommentVNode("", true)
        ], 4)
      ], 64))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ]))
}