import axios from 'axios';
import { API_URL, makeAuthenticatedRequest } from '@/utils/apiUtils';
import { useUserStore } from '@/stores/userStore';

export const reportCompanyService = {

    async getCompany(id) {

        if (!id) throw new Error('Id must be provided');

         // Check if it already exists
         const endpoint = `${API_URL}/company/${id}/`;
         let response = await makeAuthenticatedRequest(endpoint)

         if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Company data could not be fetched');
        }

    },

    async getCompanyByDetails(company_name, company_website) {

        if (!company_name || !company_website) throw new Error('Company name and website must be provided');

        const endpoint = `${API_URL}/company/search/?website=${company_website}`;
        let response = await makeAuthenticatedRequest(endpoint)

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Company data could not be fetched');
        }

    },

    async getOrMakeCompany(company_name, company_website) {

        if (!company_name || !company_website) throw new Error('Company name and website must be provided');
    
        // Check if it already exists
        const endpoint = `${API_URL}/company/search/?website=${company_website}`;
        let response = await makeAuthenticatedRequest(endpoint)

        // If it does not exist, create it
        if (response.status != 200) {
            response = await this.createCompany(company_name, company_website)
        }
        return response.data;
    },

    async createCompany (company_name, company_website) {

        if (!company_name || !company_website) throw new Error('Company name and website must be provided');

        const endpoint = `${API_URL}/company/create/?website=${company_website}`;
        const method =  'POST';
        const payload = {
            name: company_name,
            website: company_website,
        };

        const response = await makeAuthenticatedRequest(endpoint, method, payload)
    
        if (response.status != 200 && response.status != 201) {
            throw new Error('Company could not be created');
        }

        return response;

    },
    async getGapClosingReport(company_id) {

        if (!company_id) throw new Error('Company ID must be provided');

        var reportData = 'Report does not exists';
        var doesReportExists = false;
        var isReportComplete = false;
        var gciId = null;


        const endpoint = `${API_URL}/company/${company_id}/gci/?full=True`;

        const response = await makeAuthenticatedRequest(endpoint)

        if (response.status === 200) {
            reportData = response.data;
            doesReportExists = true;
            isReportComplete = response.data.is_complete;
            gciId = response.data.id;
        }

        return [reportData, doesReportExists, isReportComplete, gciId];
    
    },
    async createGapClosingReport (companyId) {
        if (!companyId) throw new Error('Company ID must be provided');
    
        var endpoint = `${API_URL}/gci/`;
        var method =  'POST';
        const payload = {
            company: companyId,
        };
    
        const response = await makeAuthenticatedRequest(endpoint, method, payload)
    
        if (response.status != 201) {
            throw new Error('Gap Closing Report could not be created');
        }
        return response.data.id;
    },
    async startResearch(company_id) {
        if (!company_id) {
            throw new Error('Company ID must be provided');
        }

        const endpoint = `${API_URL}/company/start_research/`;
        const method = 'POST';
        const payload = {
            company_id: company_id,
        };

        try {
            const response = await makeAuthenticatedRequest(endpoint, method, payload);

            if (response.status !== 200) {
                throw new Error('Research could not be started');
            }

            return response.data;
        } catch (error) {
            throw new Error('Error research');
        }
    },

    async startCheckResearch (gciId, company, isReportComplete=false) {

        const endpoint = `${API_URL}/gci/${gciId}/research/is_ready`;
    
        const response = await makeAuthenticatedRequest(endpoint)

        if (response.status !== 200) {
            if (response.status === 404) {
                return await this.startResearch(company)
            } else {
                throw new Error('Research could not be retrieved');
            }
    
        }
    
        if (isReportComplete == false && response.data.has_active_failure === false && response.data.kick_off_research === true) return await this.startResearch(company)
    
        return response.data;
    },

    async getLinkedInData (company_id, founder) {

        if (!founder.linkedin_url) return false;

        if (!company_id) return false;

        founder.linkedin_url = founder.linkedin_url.replace('https://www.linkedin.com/in/', '')

        const endpoint = `${API_URL}/company/staff/linkedin/?company_id=${company_id}&linkedin_profile_id=${founder.linkedin_url}`;

        console.log(endpoint)

        const response = await makeAuthenticatedRequest(endpoint);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('LinkedIn data could not be fetched');
        }

    }
   
}