import { getRouter } from './globalRouter';
import { useUserStore } from '@/stores/userStore';
import { useRouter } from 'vue-router';
import { RequestInit } from 'node-fetch';

export const API_URL = process.env.VUE_APP_BACKEND_BASE_URL + "/api";
export const GCI_SSE_ULR = process.env.VUE_APP_AWS_LAMBDA_URL;
export const CV_FOLLOWUP_URL = process.env.VUE_APP_AWS_CV_FOLLOWUP_URL;
export const CV_INVESTMENTMEMO_URL = process.env.VUE_APP_AWS_CV_INVESTMENTMEMO_URL;

export const handleRequest = async (request) => {
  try {
    return await request();
  } catch (error) {
    console.error('API request error:', error);
    throw new Error('Failed to complete API request');
  }
};

export const makeAuthenticatedRequest = async (endpoint: string, method = "GET", payload = {}) => {
  const userStore = useUserStore();

  let jwt = '';

  try {
    jwt = await userStore.getValidAccessToken();
  } catch (error: unknown) {
    if (error instanceof Error && error.message === "No valid tokens available.") {
      const router = getRouter();
      if (router) return router.push({ name: 'LoginPage' });
    }
    throw error;
  }

  const options: RequestInit & { headers: Record<string, string> } = {
    method,
    headers: { Authorization: `Bearer ${jwt}` },
  };

  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    options.headers['Content-Type'] = 'application/json';
    (options as RequestInit & { body: string }).body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { data: data, status: response.status };
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error('Failed to fetch data from the server');
  }
}

export interface StreamResponse {
  [Symbol.asyncIterator](): AsyncIterator<string>;
}

export async function fetchStreamData(endpoint: string, method = 'GET'): Promise<StreamResponse> {  
  const userStore = useUserStore();
  const router = useRouter();

  let token = '';

  try {
    token = await userStore.getValidAccessToken();
  } catch (error: unknown) {
    if (error instanceof Error && error.message === "No valid tokens available.") {
      if (router) {
        router.push({ name: 'LoginPage' });
        throw new Error('Authentication required');
      }
    }
    throw error;
  }

  const fetchOptions: RequestInit = {
    method,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'text/event-stream'
    },
  };

  const response = await fetch(endpoint, fetchOptions);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (!response.body) {
    throw new Error('No response body');
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");

  return {
    async *[Symbol.asyncIterator]() {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        yield decoder.decode(value, { stream: true });
      }
    }
  };
}