<template>
  <b-modal 
    v-model="isOpen"
    centered 
    :title="!streamedData && !isDrafting ? 'Reason for Passing' : (isDrafting && !streamedData ? 'Writing draft...' : 'Draft reply')" 
    hide-footer 
    :dialog-class="!streamedData && !isDrafting ? 'modal-sm' : 'modal-lg'" 
    @hide="close"
  >
    <PassContent
      :company-id="companyId"
      :company-name="companyName"
      :email-address="emailAddress"
      :recipient-name="recipientName"
      :is-actions-enabled="isActionsEnabled"
      @update-status="handleUpdateStatus"
      @update-streamedData="handleUpdateStreamedData"
      @update-isDrafting="handleUpdateIsDrafting"
    />
  </b-modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue';
import PassContent from './PassContent.vue';

export default defineComponent({
  name: 'PassModal',
  components: {
    PassContent
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
    companyName: {
      type: String,
      required: false,
    },
    emailAddress: {
      type: String,
      required: false,
    },
    recipientName: {
      type: String,
      required: false,
    },
    isActionsEnabled: {
      type: Boolean as PropType<boolean | null>,
      default: null,
    }
  },
  emits: ['update:modelValue', 'update-status'],
  setup(props, { emit }) {
    const isOpen = ref(props.modelValue);
    const streamedData = ref('');
    const isDrafting = ref(false);

    watch(() => props.modelValue, (newValue) => {
      isOpen.value = newValue;
    });

    watch(isOpen, (newValue) => {
      emit('update:modelValue', newValue);
    });

    const close = () => {
      isOpen.value = false;
      streamedData.value = ''; 
      isDrafting.value = false;
      handleUpdateStreamedData('');
      handleUpdateIsDrafting(false);
    };

    const handleUpdateStatus = (companyId: number, status: string, reason: string) => {
      emit('update-status', companyId, status, reason);
    };

    const handleUpdateStreamedData = (newValue: string) => {
      streamedData.value = newValue;
    };

    const handleUpdateIsDrafting = (newValue: boolean) => {
      isDrafting.value = newValue;
    };

    return {
      isOpen,
      streamedData,
      close,
      handleUpdateStatus,
      handleUpdateStreamedData,
      handleUpdateIsDrafting
    };
  }
});
</script>