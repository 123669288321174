<template>
  <div class="d-block">
    <div class="btn-group-vertical" role="group" style="width: 100%" v-if="!streamedData && !isDrafting && !savedStatus">
      <template v-for="reason in passReasons" :key="reason.id">
        <input type="radio" class="btn-check" name="btnradio" :id="reason.id" autocomplete="off" 
               v-model="selectedID" :value="reason.id">
        <label class="btn btn-outline-primary" :for="reason.id" @click="selectedID = reason.id">
          {{ reason.label }}
        </label>
      </template>
    </div>

    <div v-if="savedStatus">
      <div class="d-flex flex-column mb-3">
        <h6>Saved status</h6>
      </div>
    </div>

    <div v-if="isDrafting && !streamedData">
      <div class="d-flex flex-column align-items-center justify-content-center mb-3">
        <div class="spinner-border mt-3" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="position-relative" v-if="(streamedData && isDrafting) || finishedDrafting">
      <div class="mb-3">
        <label for="emailTo" class="form-label">To:</label>
        <input 
          type="email" 
          class="form-control" 
          id="emailTo" 
          v-model="localEmailAddress"
        >
      </div>

      <div class="mb-3">
        <label for="emailSubject" class="form-label">Subject:</label>
        <input 
          type="text" 
          class="form-control" 
          id="emailSubject" 
          v-model="emailSubject"
        >
      </div>
      
      <div class="mb-3">
        <label for="emailMessage" class="form-label">Reply:</label>
        <QuillEditor
            v-model:content="streamedData"
            :modules="editorModules"
            theme="snow"
            contentType="text"
        />
        <button @click="copyToClipboard" class="btn btn-sm btn-secondary position-absolute bottom-0 end-0 mb-1 me-1"><i class="flaticon-copy"></i> </button>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <template v-if="!streamedData && !isDrafting && !savedStatus">
        <template v-if="isActionsEnabled">
          <b-button class="mt-3 btn btn-secondary btn-sm" @click="save">Save</b-button>
          <b-button class="mt-3 btn btn-primary btn-sm" @click="saveDraft">Save & Draft Email</b-button>
        </template>
        <template v-else>
          <b-button class="mt-3 btn btn-primary btn-sm w-100" @click="saveDraft">Draft Pass Email</b-button>
        </template>
      </template>

      <template v-if="streamedData">
        <div class="form-check mt-3">
          <input class="form-check-input" type="checkbox" v-model="alwaysOpenInClient" id="alwaysOpenInClient">
          <label class="form-check-label" for="alwaysOpenInClient">
            Auto Open {{ loginType === 'gsuite' ? 'Gmail' : 'Email App' }}
          </label>
        </div>
        <b-button class="mt-3 btn btn-primary btn-sm" @click="openEmail">Open in {{ loginType === 'gsuite' ? 'Gmail' : 'Email App' }}</b-button>
      </template>
    </div>

    <div 
      class="alert alert-success position-fixed bottom-0 end-0 m-3 fade show" 
      role="alert"
      v-if="showCopyAlert"
    >
      Copied to clipboard!
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, PropType } from 'vue';
import * as followupService from '@/api/followupService';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from "quill-blot-formatter"
import { statusService } from '@/api/statusService';
import { StreamResponse } from '@/utils/apiUtils';

export default defineComponent({
  name: 'PassContent',
  components: {
    QuillEditor
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    companyName: {
      type: String,
      required: false,
    },
    emailAddress: {
      type: String,
      required: false,
    },
    recipientName: {
      type: String,
      required: false,
    },
    isActionsEnabled: {
      type: Boolean as PropType<boolean | null>,
      default: null,
    }
  },
  emits: ['update-status', 'update-streamedData', 'update-isDrafting'],
  setup(props, { emit }) {
    const streamedData = ref('');
    const selectedID = ref('');
    const alwaysOpenInClient = ref(false);
    const isDrafting = ref(false);
    const loginType = ref(localStorage.getItem('login_type') || null);
    const emailSubject = ref(`Follow-up To Your Pitch${props.companyName ? ` for ${props.companyName}` : ''}`);
    const localEmailAddress = ref(props.emailAddress || '');
    const savedStatus = ref(false);
    const finishedDrafting = ref(false);
    const editorModules = {
      module: BlotFormatter,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean']
      ],
      skin: 'snow'
    }

    const passReasons = [
      { id: 'LOT', value: 'Lack of Traction', label: 'Lack of Traction' },
      { id: 'TS', value: 'Too Soon', label: 'Too Soon' },
      { id: 'NVCS', value: 'Not VC Scaleable', label: 'Not VC Scaleable' },
      { id: 'NFVCF', value: 'Not for VC Funding', label: 'Not for VC Funding' },
      { id: 'NGC', value: 'Not Gap Closing', label: 'Not Gap Closing' },
      { id: 'NWG', value: 'Not within Geography', label: 'Not within Geography' },
      { id: 'NIA', value: 'Not Industry Aligned', label: 'Not Industry Aligned' },
      { id: 'NWS', value: 'Not within Stage', label: 'Not within Stage' },
    ];

    const showCopyAlert = ref(false);

    watch(alwaysOpenInClient, (newValue) => {
      localStorage.setItem('alwaysOpenInClient', JSON.stringify(newValue));
    });

    watch(() => props.emailAddress, (newValue) => {
      localEmailAddress.value = newValue || '';
    });

    watch(streamedData, (newValue) => {
      emit('update-streamedData', newValue);
    });

    watch(isDrafting, (newValue) => {
      emit('update-isDrafting', newValue);
    });

    onMounted(() => {
      const savedPreference = localStorage.getItem('alwaysOpenInClient');
      if (savedPreference !== null) {
        alwaysOpenInClient.value = JSON.parse(savedPreference);
      }
    });

    const openEmail = () => {
      const companyName = props.companyName || '';
      const email = localEmailAddress.value || '';
      const subject = emailSubject.value;
      const body = encodeURIComponent(streamedData.value);

      let url;
      if (loginType.value === 'gsuite') {
        url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
      } else {
        url = `mailto:${email}?subject=${subject}&body=${body}`;
      }

      window.open(url, '_blank');
    };

    const reset = () => {
      selectedID.value = '';
      isDrafting.value = false;
      finishedDrafting.value = false;
      streamedData.value = '';
    };

    const updateStatus = async (isDraft: boolean) => {
      const selectedReason = passReasons.find(reason => reason.id === selectedID.value)?.value || '';

      try {
        await statusService.updateCompaniesStatus([props.companyId], 'PASD', selectedReason);
      } catch (error) {
        console.error('Error updating status:', error);
      }

      emit('update-status', props.companyId, 'Passed', selectedReason);

      if (isDraft) {
        isDrafting.value = true;

        try {
          const stream: StreamResponse = await followupService.generateFollowup(props.companyId, selectedID.value);
          const greeting = props.recipientName ? `${props.recipientName},\n\n` : 'Hi,\n\n';
          let localStreamedData = greeting; // Initialize with greeting

          let buffer = '';
          for await (const chunk of stream) {
            if (!isDrafting.value) {
              break;
            }
            
            buffer += chunk;
            
            if (/[.!?]\s*$/.test(buffer)) {
              const cleanedText = buffer
                .replace(/\s+/g, ' ')
                .trim();
              
              localStreamedData += cleanedText + (cleanedText.endsWith('.') ? '\n\n' : ' ');
              buffer = ''; 
            }
          }

          if (buffer.trim()) {
            localStreamedData += buffer.trim();
          }

          finishedDrafting.value = true;

          streamedData.value = localStreamedData;

          if (alwaysOpenInClient.value && isDrafting.value) {
            openEmail();
          }

        } catch (error) {
          console.error('Error generating followup:', error);
        }
      } else {
        savedStatus.value = true;
        reset();
      }
    };

    const copyToClipboard = () => {
        if (window.parent !== window) {
            window.parent.postMessage({
                type: 'copy-to-clipboard',
                data: streamedData.value
            }, '*');
            return;
        } 

        navigator.clipboard.writeText(streamedData.value)
            .then(() => {
                showCopyAlert.value = true;
                setTimeout(() => {
                    showCopyAlert.value = false;
                }, 2000); // Hide after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };


    return {
      selectedID,
      passReasons,
      streamedData,
      alwaysOpenInClient,
      openEmail,
      copyToClipboard,
      isDrafting,
      loginType,
      editorModules,
      emailSubject,
      saveDraft: () => updateStatus(true),
      save: () => updateStatus(false),
      finishedDrafting,
      localEmailAddress,
      savedStatus,
      showCopyAlert,
    };
  }
});
</script>

<style scoped>
.alert {
  z-index: 9999;
}
</style>
