<template>
  <template v-if="!isLoginPage && !isRegisterPage && !isForgotPassword && !isLogout && !isResetPassword && !isPrivacyPage && !isTermsPage">
    <MainHeader v-if="!shouldHideHeader" />
    <MainSidebar v-if="!shouldHideHeader" />
    <div class="main-content d-flex flex-column transition overflow-hidden" 
         :style="{ 
           paddingTop: shouldHideHeader ? '20px' : '', 
           paddingLeft: shouldHideHeader && shouldHideFooter ? '0' : shouldHideHeader ? '25px' : '',
           paddingRight: shouldHideHeader && shouldHideFooter ? '0' : ''
         }">
      <router-view />
      <MainFooter v-if="!shouldHideFooter" />
    </div>
  </template>
  <template v-else>
    <div class="pt-20 d-flex flex-column transition overflow-hidden">
      <router-view />
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, watchEffect, computed } from "vue";
import { useRoute } from 'vue-router';
import stateStore from "@/stores/stateStore";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const route = useRoute();
    const isLoginPage = computed(() => route.path === '/login');
    const isRegisterPage = computed(() => route.path === '/register');
    const isForgotPassword = computed(() => route.path === '/forgot-password');
    const isLogout = computed(() => route.path === '/logout');
    const isResetPassword = computed(() => route.path.includes('/reset-password'));
    const isPrivacyPage = computed(() => route.path === '/privacy');
    const isTermsPage = computed(() => route.path === '/terms');
    const shouldHideHeader = computed(() => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('hide_header') === 'true';
    });

    const shouldHideFooter = computed(() => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('hide_footer') === 'true';
    });

    const stateStoreInstance = stateStore;

    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });

    watchEffect(() => {
      document.body.classList.forEach(className => {
        if (className.startsWith('page-')) {
          document.body.classList.remove(className);
        }
      });
      
      const path = route.path.substring(1);
      const cleanPath = path.split('/')[0];
      if (cleanPath) {
        document.body.classList.add(`page-${cleanPath}`);
        // remove body background for chrome iframe
        if ( cleanPath === 'reply' && shouldHideFooter.value && shouldHideHeader.value ) {
          document.body.classList.remove("bg-body-secondary");
        }
      }
    });

    return {
      isLoginPage,
      isRegisterPage,
      isForgotPassword,
      isLogout,
      isResetPassword,
      isPrivacyPage,
      isTermsPage,
      shouldHideHeader,
      shouldHideFooter
    };
  },
});
</script>