import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PassContent = _resolveComponent("PassContent")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
    centered: "",
    title: !_ctx.streamedData && !_ctx.isDrafting ? 'Reason for Passing' : (_ctx.isDrafting && !_ctx.streamedData ? 'Writing draft...' : 'Draft reply'),
    "hide-footer": "",
    "dialog-class": !_ctx.streamedData && !_ctx.isDrafting ? 'modal-sm' : 'modal-lg',
    onHide: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PassContent, {
        "company-id": _ctx.companyId,
        "company-name": _ctx.companyName,
        "email-address": _ctx.emailAddress,
        "recipient-name": _ctx.recipientName,
        "is-actions-enabled": _ctx.isActionsEnabled,
        onUpdateStatus: _ctx.handleUpdateStatus,
        onUpdateStreamedData: _ctx.handleUpdateStreamedData,
        onUpdateIsDrafting: _ctx.handleUpdateIsDrafting
      }, null, 8, ["company-id", "company-name", "email-address", "recipient-name", "is-actions-enabled", "onUpdateStatus", "onUpdateStreamedData", "onUpdateIsDrafting"])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "dialog-class", "onHide"]))
}